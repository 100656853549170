import React from 'react';
import './App.css';
import { Button, Checkbox, InputGroup } from "@blueprintjs/core";

interface IState {
    seed: string;
    ready: boolean;
    generating: boolean;
    bw: boolean;
}

class App extends React.Component<{}, IState> {
    private canvas: HTMLCanvasElement | null = null;

    constructor(props: {}) {
        super(props);

        this.state = {
            seed: "",
            ready: false,
            generating: false,
            bw: true
        };
    }

    render() {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    maxHeight: "100vw",
                    flexDirection: "column",
                    paddingTop: 20
                }}
            >
                <div
                    style={{
                        justifyContent: "flex-start",
                        flex: 1,
                        display: this.state.ready ? undefined : "none",
                        borderRadius: 5,
                        border: "ridge",
                        backgroundColor: "#FFF"
                    }}>
                    <canvas
                        ref={canvas => this.canvas = canvas}
                        width="100"
                        height="100"
                        style={{ height: "100%" }}
                    />
                </div>
                {this.renderInput()}
            </div>
        );
    }

    private renderInput = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 1000,
                    justifyContent: "center",
                    padding: 20
                }}
            >
                <InputGroup
                    placeholder="Seed (number)"
                    onChange={this.setSeed}
                    value={this.state.seed}
                    disabled={this.state.generating}
                />
                <div style={{ height: 10 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        style={{ margin: 0, paddingRight: 10 }}
                        label="B&W"
                        onClick={this.toggleBW}
                        checked={this.state.bw}
                    />
                    <Button
                        onClick={this.generate}
                        intent="primary"
                        disabled={this.state.generating || isNaN( parseFloat( this.state.seed ) )}
                        fill={true}
                    >
                        {this.state.generating ? "Generating..." : "Generate"}
                    </Button>
                </div>
            </div>
        );
    }

    private setSeed = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ seed: e.target.value });
    }

    private generate = async () => {
        this.setState({ generating: true });
        const seed = parseFloat( this.state.seed ).toString();
        const res = await (
            await fetch(
                `https://qn5ng0gq4d.execute-api.us-west-1.amazonaws.com/default/sym-art?seed=${seed}&bw=${this.state.bw}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
        ).json();
        const data = res.plane;
        const canvas = this.canvas;
        if (!canvas) {
            return;
        }

        canvas.width = res.size;
        canvas.height = res.size;

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            return;
        }

        const imageData = ctx.createImageData(res.size, res.size);
        for (let i = 0; i < data.length; i++) {
            if (res.bw) {
                const val = Math.round(data[i]);
                imageData.data[i * 4 + 0] = 0;
                imageData.data[i * 4 + 1] = 0;
                imageData.data[i * 4 + 2] = 0;
                imageData.data[i * 4 + 3] = 255 - val;
            } else {
                imageData.data[i] = data[i];
            }
        }

        ctx.putImageData(imageData, 0, 0);

        this.setState({ ready: true, generating: false, seed });
    }

    private toggleBW = () => {
        this.setState({ bw: !this.state.bw });
    }
}

export default App;
